define("discourse/plugins/discourse-docs/discourse/components/docs-topic-list", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("order"), _dec2 = (0, _decorators.default)("order"), (_obj = {
    classNames: "docs-topic-list",
    sortTitle(order) {
      return order === "title";
    },
    sortActivity(order) {
      return order === "activity";
    },
    // need to handle clicks here since links are in a raw view
    click(e) {
      if (e.target.classList.contains("docs-topic-link")) {
        const topicId = e.target.dataset.topicId;
        this.selectTopic(topicId);
        return false;
      }
    },
    sortListActivity() {
      this.sortBy("activity");
      return false;
    },
    sortListTitle() {
      this.sortBy("title");
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "sortTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortTitle"), _obj), _applyDecoratedDescriptor(_obj, "sortActivity", [_dec2], Object.getOwnPropertyDescriptor(_obj, "sortActivity"), _obj), _applyDecoratedDescriptor(_obj, "sortListActivity", [_object.action], Object.getOwnPropertyDescriptor(_obj, "sortListActivity"), _obj), _applyDecoratedDescriptor(_obj, "sortListTitle", [_object.action], Object.getOwnPropertyDescriptor(_obj, "sortListTitle"), _obj)), _obj)));
});