define("discourse/plugins/discourse-docs/discourse/controllers/docs", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_obj = {
    indexController: (0, _controller.inject)("docs.index"),
    updateSelectedCategories(category) {
      this.indexController.send("updateSelectedCategories", category);
      return false;
    },
    updateSelectedTags(tag) {
      this.indexController.send("updateSelectedTags", tag);
      return false;
    },
    updateSelectedGroups(group) {
      this.indexController.send("updateSelectedGroups", group);
      return false;
    },
    performSearch(term) {
      this.indexController.send("performSearch", term);
      return false;
    }
  }, (_applyDecoratedDescriptor(_obj, "updateSelectedCategories", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateSelectedCategories"), _obj), _applyDecoratedDescriptor(_obj, "updateSelectedTags", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateSelectedTags"), _obj), _applyDecoratedDescriptor(_obj, "updateSelectedGroups", [_object.action], Object.getOwnPropertyDescriptor(_obj, "updateSelectedGroups"), _obj), _applyDecoratedDescriptor(_obj, "performSearch", [_object.action], Object.getOwnPropertyDescriptor(_obj, "performSearch"), _obj)), _obj));
});